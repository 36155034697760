@charset "ISO-8859-1";

html {
  scroll-behavior: smooth !important;
  font-family: "Square Peg", cursive !important;
}
a {
  text-decoration: none !important;
  cursor: pointer !important;
}

.subscribe {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.clients {
  -webkit-border-top-left-radius: 50px;
  -webkit-border-bottom-right-radius: 50px;
  -moz-border-radius-topleft: 50px;
  -moz-border-radius-bottomright: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.site-text {
  font-family: "Noto Sans", sans-serif !important;
}
